import { FC } from 'react';

import { useGetWalletSettingsQuery } from '@entities/wallets';
import { LinearProgress } from '@mui/material';
import ErrorMessage from '@shared/components/ErrorMessage';

import { WalletSettingsForm } from './WalletSettingsForm';

interface IProps {
  parent_id: string;
  parent_name: string;
  refetch: () => void;
}

export const EditWalletSettings: FC<IProps> = ({ parent_id, parent_name, refetch }) => {
  const { data, isLoading, isError, error } = useGetWalletSettingsQuery(parent_id);

  if (isLoading) {
    return <LinearProgress variant='query' />;
  }

  if (isError) {
    return <ErrorMessage error={error} />;
  }

  return (
    <WalletSettingsForm
      defaultValues={data.data}
      title={`Edit Wallet Settings for ${parent_name}`}
      parent_id={parent_id}
      refetch={refetch}
    />
  );
};
