export const useFavicon = () => {
  const getFaviconUrl = () => {
    const domain = window.location.hostname;

    switch (domain) {
      case 'prod.lakhpay.com':
      case 'prod.l888pay.com':
        return process.env.PUBLIC_URL + '/favicon-lakhpay.ico';

      default:
        return process.env.PUBLIC_URL + '/favicon.ico';
    }
  };

  return getFaviconUrl();
};
