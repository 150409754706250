import { useState } from 'react';

import { HideIfRole, withRoleAccess } from '@app/routes';
import type { IOrderTransactionTableColumns } from '@entities/orders/transaction';
import { useOrdersSettlements } from '@entities/orders/transaction';
import { MerchantsSelectAsync } from '@features/merchants';
import ModalOpenBtn from '@features/modal-open-btn';
import ErrorMessage from '@shared/components/ErrorMessage';
import { FormItemWithError } from '@shared/components/Form';
import Loading from '@shared/components/Loading';
import LoadingSpinner from '@shared/components/LoadingSpinner';
import { Pagination, PaginationRows } from '@shared/components/Pagination';
import {
  Table,
  THead,
  TBody,
  TableActions,
  TableActionsItem,
  TableContainer,
  TableProvider,
} from '@shared/components/Table';

import { useTableSettings } from './settings/useTableSettings';

const SettlementsWidget = () => {
  const [merchant_id, setMerchantId] = useState('');

  const {
    settlements,
    isLoading,
    isError,
    error,
    isFetching,
    refetch,

    filter,
  } = useOrdersSettlements({ merchant_id });

  const { columnTitles, renderRow, NewSettlement } = useTableSettings({ refetch });

  if (isLoading) return <LoadingSpinner />;
  if (isError) return <ErrorMessage error={error} />;

  if (settlements) {
    const NewSettlementBtn = withRoleAccess(ModalOpenBtn, ['admin']);
    return (
      <div className='settlements-widget'>
        {isFetching && <Loading />}

        <TableProvider<IOrderTransactionTableColumns> columnTitles={columnTitles} {...filter}>
          <HideIfRole roles={['merchant']}>
            <TableActions>
              <TableActionsItem>
                <FormItemWithError
                  name='filter.merchant_id'
                  label='Merchant'
                  isError={isError}
                  error={error}
                >
                  <MerchantsSelectAsync onChangeMerchant={setMerchantId} />
                </FormItemWithError>
              </TableActionsItem>
              <TableActionsItem>
                <NewSettlementBtn text='New' modalContent={<NewSettlement />} />
              </TableActionsItem>
            </TableActions>
          </HideIfRole>

          <TableContainer>
            <Table>
              <THead />
              <TBody items={settlements.data} renderItem={renderRow} />
            </Table>
          </TableContainer>
          <div className='pagination-container'>
            <Pagination data={settlements.meta} />
            <PaginationRows />
          </div>
        </TableProvider>
      </div>
    );
  }

  return null;
};

export default SettlementsWidget;
