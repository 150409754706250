import { FC } from 'react';

import { Link } from 'react-router-dom';

import CopyToClipboardButton from '@features/copy-to-clipboard-button';
import { reduceString } from '@shared/utils/reduceString';

interface IProps {
  text: string;
  size?: number;
  needReduce?: boolean;
  navigateTo?: string;
}

const ReduceAndCopyString: FC<IProps> = ({ text, needReduce = true, size = 4, navigateTo }) => {
  if (!text || text === 'null') return null;

  const renderText = () => {
    if (navigateTo && text !== 'N/A') {
      return (
        <div>
          <Link to={navigateTo}>{needReduce ? reduceString({ text, size }) : text}</Link>
        </div>
      );
    }

    return needReduce ? reduceString({ text, size }) : text;
  };

  return (
    <div className='table-cell-items' style={{ overflow: 'hidden' }}>
      {renderText()}
      <CopyToClipboardButton text={text} />
    </div>
  );
};

export default ReduceAndCopyString;
