import { useEffect, useRef } from 'react';

import { useSelector } from 'react-redux';

import { useCurrentUser } from '../../../entities/user';
import { selectTestMode } from '../model/testModeSlice';

type Props = {
  filterKey?: string;
  currentFilter: URLSearchParams;
  updateFilter: (updates: object) => void;
};

export function useMerchantTestMode({
  currentFilter,
  updateFilter,
  filterKey = 'merchant_is_test',
}: Props) {
  const user = useCurrentUser().currentUser;

  let isTestMode = useSelector(selectTestMode);
  if (user?.is_test) isTestMode = true;

  const key = `filter[${filterKey}]`;
  const isFirstRun = useRef(true);

  // Before we make any queries, set the filter to the current test mode
  if (!currentFilter.get(key)) {
    updateFilter({ [key]: isTestMode ? '1' : '0' });
  }

  // Skip the first run, we already set the correct filter
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }

    updateFilter({ [key]: isTestMode ? '1' : '0' });
  }, [isTestMode]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    isTestMode,
  };
}
