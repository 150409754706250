import { ReactNode } from 'react';

import { IMID } from '@entities/mid';
import { FormItem } from '@shared/components/Form';
import { IBaseObject, TObject } from '@shared/types/fetch-data';
import { getTypedObjectEntries } from '@shared/types/typedObjectEntries';
import { isObject } from '@shared/utils/isObject';
import { transformObjectKeyToTitle } from '@shared/utils/transformObjectKeyToTitle';

interface IProps<T> {
  entity: T;
  title: string;
  renamedLabels?: { [K in keyof T | string]?: string };
  deepEntityValues?: { [K in keyof T]?: string[] | Record<string, string[]> };
  additionalData?: { label: string; element: ReactNode }[];
  excludeKeys?: (keyof T)[];
}

export const ModalViewEntity = <T extends IBaseObject>({
  entity,
  title,
  renamedLabels,
  deepEntityValues,
  additionalData,
  excludeKeys = [],
}: IProps<T>) => {
  const renderItem = (key: keyof T | string, value: T[keyof T], labelPrefix?: string) => {
    const keyString = String(key);
    const valueString = String(value);
    const label = labelPrefix
      ? labelPrefix
      : renamedLabels?.[key] || `${transformObjectKeyToTitle(keyString)}:`;

    switch (key) {
      case 'description':
        return (
          <FormItem key={keyString} name={keyString} label={label}>
            <textarea name={keyString} id={keyString} readOnly defaultValue={valueString} />
          </FormItem>
        );

      case 'logo_url':
        return (
          <FormItem key={keyString} name={keyString} label={label}>
            <div className='form-field-pic'>
              <img src={valueString} alt={valueString} />
            </div>
          </FormItem>
        );

      case 'currency_method_id': {
        const currencyMethod = entity['currency_method' as keyof T] as IMID['currency_method'];
        const value = `${currencyMethod?.currency?.code} — ${currencyMethod?.method?.name}`;
        return (
          <FormItem key={keyString} name={keyString} label={label}>
            <input type='text' name={keyString} id={keyString} defaultValue={value} readOnly />
          </FormItem>
        );
      }

      default:
        return (
          <FormItem key={keyString} name={keyString} label={label}>
            <input
              type='text'
              name={keyString}
              id={keyString}
              defaultValue={value && valueString}
              readOnly
            />
          </FormItem>
        );
    }
  };

  const renderDeepValues = (
    value: TObject,
    path: string[],
    deepValuesConfig: string[] | Record<string, string[]>,
  ): ReactNode[] | null => {
    const createDeepLabel = (key: string) => {
      const fullPath = [...path, key];
      const joinedPath = fullPath.join('_');
      return `${renamedLabels?.[joinedPath] || transformObjectKeyToTitle(joinedPath)}:`;
    };

    if (Array.isArray(deepValuesConfig)) {
      return deepValuesConfig.map(deepKey => {
        const deepValue = value[deepKey];
        const deepLabel = createDeepLabel(deepKey);
        return renderItem(deepKey, deepValue, deepLabel);
      });
    }

    if (isObject(deepValuesConfig)) {
      return Object.entries(deepValuesConfig).map(([deepKey, deepValuesConfig]) => {
        const deepValue = value[deepKey];
        if (isObject(deepValue)) {
          const fullPath = [...path, deepKey];
          return renderDeepValues(deepValue as TObject, fullPath, deepValuesConfig);
        }
        const deepLabel = createDeepLabel(deepKey);
        return renderItem(deepKey, deepValue, deepLabel);
      });
    }
  };

  return (
    <>
      <h2>{title}</h2>
      <form className='popup-form'>
        {getTypedObjectEntries(entity)
          .filter(([key]) => !excludeKeys.includes(key))
          .map(([key, value]) => {
            if (isObject(value) && !deepEntityValues?.[key]) {
              return null;
            }

            if (isObject(value) && deepEntityValues?.[key]) {
              return renderDeepValues(value, [String(key)], deepEntityValues[key]);
            }

            return renderItem(key, value);
          })}

        {additionalData &&
          additionalData.map(({ label, element }) => {
            return (
              <FormItem key={label} name={label} label={label}>
                {element}
              </FormItem>
            );
          })}
      </form>
    </>
  );
};
