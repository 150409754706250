import { FC } from 'react';

import startCase from 'lodash/startCase';

import { useCreateWalletSettingsMutation, type IWalletSettings } from '@entities/wallets';
import { FormItemWithError } from '@shared/components/Form';
import Loading from '@shared/components/Loading';
import { useModal } from '@shared/components/Modal';

import {
  assmebleWalletSettings,
  WALLET_SETTINGS_DEFAULT_VALUES,
  WALLET_SETTINGS_KEYS,
} from '../utils/assembleWalletSettings';

interface IProps {
  defaultValues?: IWalletSettings;
  title: string;
  parent_id: string;
  refetch: () => void;
}

export const WalletSettingsForm: FC<IProps> = ({
  title,
  parent_id,
  refetch,
  defaultValues = {},
}) => {
  const { closeModal } = useModal();
  const [createWalletSettings, { isLoading, isError, error }] = useCreateWalletSettingsMutation();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.target as HTMLFormElement);
    const settings = assmebleWalletSettings(formData);
    try {
      const result = await createWalletSettings({ id: parent_id, ...settings });
      if ('data' in result) {
        closeModal();
        refetch();
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      {isLoading && <Loading />}

      <h2>{title}</h2>
      <form onSubmit={handleSubmit} className='popup-form'>
        {WALLET_SETTINGS_KEYS.map(key => {
          switch (key) {
            case 'min_amount':
            case 'max_amount':
              return (
                <FormItemWithError
                  key={key}
                  name={key}
                  label={startCase(key)}
                  error={error}
                  isError={isError}
                >
                  <input
                    type='number'
                    name={key}
                    min={200}
                    max={500000}
                    defaultValue={defaultValues[key] ?? WALLET_SETTINGS_DEFAULT_VALUES[key]}
                  />
                </FormItemWithError>
              );
            case 'wallet_fee':
              return (
                <FormItemWithError
                  key={key}
                  name={key}
                  label={startCase(key)}
                  error={error}
                  isError={isError}
                >
                  <input
                    type='number'
                    name={key}
                    step={0.1}
                    min={0}
                    defaultValue={defaultValues[key] ?? WALLET_SETTINGS_DEFAULT_VALUES[key]}
                  />
                </FormItemWithError>
              );
            default:
              return (
                <FormItemWithError
                  key={key}
                  name={key}
                  label={startCase(key)}
                  error={error}
                  isError={isError}
                >
                  <input
                    type='number'
                    name={key}
                    step={1}
                    defaultValue={defaultValues[key] ?? WALLET_SETTINGS_DEFAULT_VALUES[key]}
                  />
                </FormItemWithError>
              );
          }
        })}

        <div className='form-submit'>
          <button type='button' className='secondary-btn' onClick={closeModal}>
            Cancel
          </button>
          <button type='submit' disabled={isLoading}>
            Save
          </button>
        </div>
      </form>
    </>
  );
};
