import { useFilter } from '@features/table-filter';

import { useGetCurrencyMethodsQuery } from '../api';
import type { ICurrencyMethodFilter } from '../model/types';

interface IProps {
  disableUrlFilter?: boolean;
}

export const useCurrencyMethods = ({ disableUrlFilter = false }: IProps = {}) => {
  const filter = useFilter<ICurrencyMethodFilter>({ disableUrlFilter });

  const {
    data: currencyMethods,
    isLoading,
    isError,
    error,
    isFetching,
    refetch,
  } = useGetCurrencyMethodsQuery(filter.currentFilter.toString());

  return {
    currencyMethods,
    isError,
    error,
    isLoading,
    isFetching,
    refetch,

    filter,
  };
};
