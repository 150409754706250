import { authReducer } from '@entities/auth';
import { datetimeReducer } from '@entities/datetime';
import { pageFeaturesReducer } from '@entities/page';
import { userReducer } from '@entities/user';
import { walletReducer, walletsExpandedReducer } from '@entities/wallets';
import { loadingReducer } from '@features/loading';
import { testModeReducer } from '@features/test-mode-toggler';
import { uiLockReducer } from '@features/ui-lock';
import { combineReducers } from '@reduxjs/toolkit';

import { baseApi } from './index.api';

const rootReducer = combineReducers({
  [baseApi.reducerPath]: baseApi.reducer,
  auth: authReducer,
  user: userReducer,
  datetime: datetimeReducer,
  pageFeatures: pageFeaturesReducer,
  wallet: walletReducer,
  walletsExpanded: walletsExpandedReducer,
  loading: loadingReducer,
  testMode: testModeReducer,
  uiLock: uiLockReducer,
  // add other reducers here
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
