import { FC, useEffect } from 'react';

import { useCreateWalletSettingsMutation } from '@entities/wallets';

import { WalletSettingsForm } from './WalletSettingsForm';
import { assmebleWalletSettings } from '../utils/assembleWalletSettings';

interface IProps {
  parent_id: string;
  parent_name: string;
  refetch: () => void;
}

export const NewWalletSettings: FC<IProps> = ({ parent_id, parent_name, refetch }) => {
  const [createWalletSettings] = useCreateWalletSettingsMutation();

  useEffect(() => {
    const submitDefaultSettings = async () => {
      const settings = assmebleWalletSettings(new FormData());
      try {
        await createWalletSettings({ id: parent_id, ...settings });
        refetch();
      } catch (error) {
        console.error(error);
      }
    };
    submitDefaultSettings();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <WalletSettingsForm
      title={`New Wallet Settings for ${parent_name}`}
      parent_id={parent_id}
      refetch={refetch}
    />
  );
};
