import { FC, FormEvent, useState } from 'react';

import useActions from '@app/store/hooks/useActions';
import { TUserRoles } from '@entities/user';
import { FormItemWithError } from '@shared/components/Form';
import Loading from '@shared/components/Loading';

import '../auth.css';
import useLoginFormValidation from '../hooks/useLoginFormValidation';

interface ILoginFormProps {
  role: TUserRoles;
  mutation: any; // eslint-disable-line
}

export const LoginForm: FC<ILoginFormProps> = ({ role, mutation }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [as, setAs] = useState('admin');

  const [login, { isLoading, isError: isRequestError, error: requestError }] = mutation();
  const { setToken } = useActions();

  const { isError: clientIsError, errors: clientError, validateFields } = useLoginFormValidation();

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!validateFields(email, password)) {
      return;
    }

    const loginData = role === 'admin' ? { email, password, as } : { email, password };

    try {
      const result = await login(loginData);

      if ('data' in result) {
        result.data.token && setToken(result.data);
      }
    } catch (err) {
      console.error('Caught error:', err);
    }
  };

  const isError = isRequestError || clientIsError;
  const error = isRequestError
    ? requestError
    : {
        status: 422,
        data: { errors: clientError, message: 'Please fill in all required fields.' },
      };

  return (
    <>
      {isLoading && <Loading />}

      <div className='login-form-block'>
        <h1>
          <span className='login-form-title'>{role}</span> login | Backoffice
        </h1>
        <form onSubmit={handleSubmit} action='/auth/backoffice' className='login-form'>
          <FormItemWithError name='email' label='Email' isError={isError} error={error}>
            <input
              autoComplete='email'
              type='email'
              name='email'
              value={email}
              placeholder='example@mail.com'
              onChange={e => setEmail(e.target.value)}
            />
          </FormItemWithError>

          <FormItemWithError name='password' label='Password' isError={isError} error={error}>
            <input
              autoComplete='current-password'
              type='password'
              name='password'
              value={password}
              onChange={e => setPassword(e.target.value)}
            />
          </FormItemWithError>

          {role === 'admin' && (
            <FormItemWithError name='as' label='As' isError={isError} error={error}>
              <select name='as' value={as} onChange={e => setAs(e.target.value)}>
                <option value='admin'>Admin</option>
                <option value='operator'>Operator</option>
              </select>
            </FormItemWithError>
          )}

          <div className='form-submit'>
            <button type='submit' disabled={isLoading}>
              Log in
            </button>
          </div>
        </form>
      </div>
    </>
  );
};
