import { useEffect } from 'react';

import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import useActions from '@app/store/hooks/useActions';
import { Switch } from '@mui/material';

import { selectTestMode } from '../model/testModeSlice';

import './test-mode-toggler.css';

const MESSAGES = {
  on: 'You turned on test mode, which means all test data (orders, transactions, wallets, MID, etc.) related to test merchants will be shown.',
  off: 'You turned off test mode, which means all test data (orders, transactions, wallets, MID, etc.) related to test merchants will be hidden.',
};

export const TestModeToggler = () => {
  const [searchParams] = useSearchParams();
  const { setTestMode } = useActions();
  const enabled = useSelector(selectTestMode);

  useEffect(() => {
    const initialTestMode = searchParams.get('filter[merchant_is_test]');
    if (!enabled && initialTestMode === '1') setTestMode(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleToggle = () => {
    toast.info(
      <>
        <p className='test-mode-toggle-title'>Test mode {!enabled ? 'ON' : 'OFF'}</p>
        <p className='test-mode-toggle-message'>{MESSAGES[!enabled ? 'on' : 'off']}</p>
      </>,
      {
        position: 'bottom-right',
        autoClose: 4000,
        closeOnClick: true,
        closeButton: false,
        draggable: true,
        pauseOnHover: true,
        hideProgressBar: true,
        icon: false,
      },
    );

    setTestMode(!enabled);
  };

  return (
    <div className='test-mode-switch'>
      <p>Test mode</p>
      <Switch checked={enabled} onChange={handleToggle} color='secondary' />
    </div>
  );
};
