import { baseApi } from '@app/store/index.api';

import { IResetPasswordResponse, IResetPassword, IUserResponse } from '../model/types';

const userApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getUser: builder.query<IUserResponse, void>({
      query: () => '/user',
    }),
    resetOperatorPassword: builder.mutation<IResetPasswordResponse, IResetPassword>({
      query: body => ({
        url: '/operator/reset-password',
        method: 'PATCH',
        body,
      }),
    }),
  }),
});

export const { useGetUserQuery, useLazyGetUserQuery, useResetOperatorPasswordMutation } = userApi;
