import {
  IBank,
  IBankTableColumns,
  useDeleteBankMutation,
  useEditBankMutation,
} from '@entities/bank';
import { ModalDeleteEntity } from '@features/modal-entity-delete';
import { ModalEditEntity } from '@features/modal-entity-edit';
import { ModalViewEntity } from '@features/modal-entity-view';
import { ITableActionsConfig, TableActionBtns } from '@features/table-action-btns';

import { NewBankModal } from '../components/NewBank';
import { NewBankSettings } from '../components/NewBankSettings';

interface IProps {
  refetch?: () => void;
}

export const useTableSettings = ({ refetch }: IProps) => {
  const [deleteBank] = useDeleteBankMutation();

  // THead settings
  const columnTitles: IBankTableColumns = {
    name: 'Name',
    holder_name: 'Holder Name',
    bank_validator_name: 'Validator',
    created_at: 'Created At',
    updated_at: 'Updated At',
    actions: 'Actions',
  };

  // TBody settings
  const renderRow = (key: keyof IBankTableColumns, bank: IBank) => {
    switch (key) {
      case 'actions': {
        const excludeEditKeys: (keyof IBank)[] = [
          'id',
          'bank_validator_name',
          'created_at',
          'updated_at',
          'deleted_at',
          'settings',
        ];
        const excludeViewKeys: (keyof IBank)[] = ['deleted_at', 'settings'];

        const actionBtnsConfig: ITableActionsConfig = {
          edit: {
            isEnabled: true,
            isVisible: true,
            popupElem: (
              <ModalEditEntity
                title='Edit Bank'
                entity={bank}
                excludeKeys={excludeEditKeys}
                useEditEntityMutation={useEditBankMutation}
                refetch={refetch}
              />
            ),
            popupSize: 'md',
          },
          'edit-settings': {
            isEnabled: true,
            isVisible: true,
            popupElem: (
              <NewBankSettings
                isEdit={true}
                initialMethods={bank.settings.bank_wallet_config}
                parent_id={bank.id}
                parent_name={bank.name}
                refetch={refetch}
              />
            ),
            popupSize: 'md',
          },
          view: {
            isEnabled: true,
            isVisible: true,
            popupElem: (
              <ModalViewEntity excludeKeys={excludeViewKeys} entity={bank} title='View Bank' />
            ),
            popupSize: 'md',
          },
          delete: {
            isEnabled: true,
            isVisible: true,
            popupElem: (
              <ModalDeleteEntity
                id={bank.id}
                message='Do you want to delete this bank?'
                deleteEntity={deleteBank}
                refetch={refetch}
              />
            ),
            popupSize: 'sm',
          },
        };

        return <TableActionBtns config={actionBtnsConfig} />;
      }

      default:
        return bank[key];
    }
  };

  const NewBank = () => <NewBankModal refetch={refetch} />;
  return {
    columnTitles,
    renderRow,
    NewBank,
  };
};
