import { FC } from 'react';

import {
  ICurrencyMethodResponse,
  ICurrencyMethod,
  useCurrencyMethods,
} from '@entities/currency-method';
import { SelectAsync } from '@shared/components/Form';

interface IProps {
  name?: string;
  selectedCurrencyMethod?: string;
  hasFirstSelectedOption?: boolean;
  onChangeCurrencyMethod?: (currencyMethodId: string) => void;
}

export const CurrencyMethodsSelectAsync: FC<IProps> = ({
  name = 'currency_method_id',
  hasFirstSelectedOption = false,
  selectedCurrencyMethod,
  onChangeCurrencyMethod,
}) => {
  const { currencyMethods, isLoading, isFetching, error, filter } = useCurrencyMethods({
    disableUrlFilter: true,
  });

  const firstSelectedCurrencyMethodId = hasFirstSelectedOption
    ? { selectedValue: currencyMethods?.data[0].id }
    : {};

  return (
    <SelectAsync<ICurrencyMethod, ICurrencyMethodResponse>
      name={name}
      data={currencyMethods}
      getOptionValue={currencyMethod => currencyMethod.id}
      getOptionLabel={currencyMethod => `${currencyMethod.code} — ${currencyMethod.name}`}
      filter={filter}
      filterKey='code'
      selectedValue={selectedCurrencyMethod}
      onChange={onChangeCurrencyMethod}
      {...firstSelectedCurrencyMethodId}
      isLoading={isLoading || isFetching}
      error={error}
    />
  );
};
