import { FC } from 'react';

import Skeleton from 'react-loading-skeleton';

import { IForm, useForms, IFormResponse } from '@entities/form';
import ErrorMessage from '@shared/components/ErrorMessage';
import { SelectAsync } from '@shared/components/Form';

import 'react-loading-skeleton/dist/skeleton.css';

interface IProps {
  name?: string;
  value?: string;
  onChangeForm?: (formId: string) => void;
}

export const FormsSelect: FC<IProps> = ({ name = 'form_id', value, onChangeForm }) => {
  const { forms, isLoading, error, isFetching } = useForms();

  if (isLoading) return <Skeleton height={42} />;
  if (error) {
    return (
      <>
        <select></select>
        <ErrorMessage error={error} />
      </>
    );
  }

  if (forms) {
    const hasForms = forms.data.length > 0;
    const selectedValueProp = value ? { selectedValue: value } : {};

    return (
      <SelectAsync<IForm, IFormResponse>
        name={name}
        isStatic
        data={forms}
        getOptionValue={form => form.id}
        getOptionLabel={form => form.name}
        onChange={onChangeForm}
        {...selectedValueProp}
        isLoading={isLoading || isFetching}
        isDisabled={!hasForms}
      />
    );
  }

  return null;
};
