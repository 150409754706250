import { useEffect } from 'react';

import { useFilter } from '@features/table-filter';
import { useMerchantTestMode } from '@features/test-mode-toggler';
import { useRefetchCurrentPageData } from '@shared/hooks/useRefetchCurrentPageData';

import { useGetSettlementsQuery } from '../api';
import type { IOrderTransactionFilter } from '../model/types';

interface IProps {
  merchant_id?: string;
}

export const useOrdersSettlements = ({ merchant_id }: IProps) => {
  const filter = useFilter<IOrderTransactionFilter>();
  const { currentFilter, updateFilter } = filter;

  const {
    data: settlements,
    isLoading,
    isError,
    error,
    isFetching,
    refetch,
  } = useGetSettlementsQuery(currentFilter.toString());

  useMerchantTestMode({ currentFilter, updateFilter });

  useEffect(() => {
    updateFilter({ 'filter[merchant_id]': merchant_id });
  }, [merchant_id]); // eslint-disable-line react-hooks/exhaustive-deps

  // Refetch data when timezone changes
  useRefetchCurrentPageData({ refetch });

  return {
    settlements,
    isError,
    error,
    isLoading,
    isFetching,
    refetch,

    filter,
  };
};
