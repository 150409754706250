import { FC, useEffect } from 'react';

import { ICurrency, ICurrencyResponse, useCurrencies } from '@entities/currency';
import { SelectAsync } from '@shared/components/Form';

interface IProps {
  merchantId?: string;
  mid_ids?: string;
  selectedCurrency?: string;
  name?: string;
  hasFirstSelectedOption?: boolean;
  onChangeCurrency?: (currencyId: string) => void;
  onSuccessfulFetch?: (isSuccess: boolean) => void;
}

export const CurrenciesSelectAsync: FC<IProps> = ({
  merchantId = '',
  mid_ids = '',
  name = 'currency_id',
  hasFirstSelectedOption = false,
  selectedCurrency,
  onChangeCurrency,
  onSuccessfulFetch,
}) => {
  const { currencies, isLoading, isFetching, error, isSuccess, filter } = useCurrencies({
    merchantId,
    mid_ids,

    disableUrlFilter: true,
  });

  // successful fetch to parent component
  useEffect(() => {
    if (onSuccessfulFetch && isSuccess !== undefined) {
      onSuccessfulFetch(isSuccess);
    }
  }, [isSuccess, onSuccessfulFetch]);

  const firstSelectedCurrencyId = hasFirstSelectedOption
    ? { selectedValue: currencies?.data[0].id }
    : {};

  return (
    <SelectAsync<ICurrency, ICurrencyResponse>
      name={name}
      data={currencies}
      getOptionValue={currency => currency.id}
      getOptionLabel={currency => currency.code}
      filter={filter}
      filterKey='code'
      selectedValue={selectedCurrency}
      onChange={onChangeCurrency}
      {...firstSelectedCurrencyId}
      isLoading={isLoading || isFetching}
      error={error}
    />
  );
};
