import { IProviderSettings } from '@entities/provider';

export const PROVIDER_SETTINGS_MIN_AMOUNT = 200;
export const PROVIDER_SETTINGS_MAX_AMOUNT = 500000;
export const PROVIDER_SETTINGS_KEYS: (keyof IProviderSettings)[] = [
  'min_amount',
  'max_amount',
  'chargeback_fee',
  'deposit_fee',
  'withdrawal_fee',
  'settlement_fee',
  'refund_fee',
  'deposit_transaction_fee',
  'withdrawal_transaction_fee',
  'settlement_transaction_fee',
  'refund_transaction_fee',
  'chargeback_transaction_fee',
];

export const assembleProviderSettings = (fd: FormData) => {
  return PROVIDER_SETTINGS_KEYS.reduce((acc, key) => {
    acc[key] = fd.get(key) ? String(fd.get(key)) : '0';
    return acc;
  }, {} as IProviderSettings);
};
