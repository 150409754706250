import { FC, useState, useEffect, FormEvent } from 'react';

import type { IMID, IMIDSetting, TMIDSettingCodes, TEditMIDSettings } from '@entities/mid';
import { useEditMIDSettingMutation, useGetMIDSettingsQuery } from '@entities/mid';
import { FormItemWithError } from '@shared/components/Form';
import Loading from '@shared/components/Loading';
import LoadingSpinner from '@shared/components/LoadingSpinner';
import { useModal } from '@shared/components/Modal';
import { getTypedObjectKeys } from '@shared/types/typedObjectEntries';
import { isEmptyObject } from '@shared/utils/isEmptyObject';
import { transformObjectKeyToTitle } from '@shared/utils/transformObjectKeyToTitle';

type IMIDSettingForm = Record<TMIDSettingCodes, string>;

interface IProps {
  mid: IMID;
}

export const EditMIDPricing: FC<IProps> = ({ mid }) => {
  const [editable, setEditable] = useState(false);
  const { closeModal } = useModal();

  const { data: midSettings, isLoading } = useGetMIDSettingsQuery({ id: mid.id });
  const [editEntity, { isLoading: isEditLoading, isError, error, isSuccess }] =
    useEditMIDSettingMutation();

  const [minAmount, setMinAmount] = useState<string>('');
  const [maxAmount, setMaxAmount] = useState<string>('');

  useEffect(() => {
    if (midSettings) {
      const minSetting = midSettings.data.find(setting => setting.setting_code === 'min_amount');
      const maxSetting = midSettings.data.find(setting => setting.setting_code === 'max_amount');
      if (minSetting) setMinAmount(minSetting.value);
      if (maxSetting) setMaxAmount(maxSetting.value);
    }
  }, [midSettings]);

  const getFormLabels = (setting_code: TMIDSettingCodes, mid: IMID) => {
    const currency = mid.currency_method.currency.code;

    const formLabels: IMIDSettingForm = {
      deposit_fee: 'Deposit fee, %',
      withdrawal_fee: 'Withdrawal fee, %',
      settlement_fee: 'Settlement fee, %',
      refund_fee: `Refund fee, ${currency}`,
      chargeback_fee: `Chargeback fee, ${currency}`,
      hold: 'Hold, days',
      rolling_reserve: 'Rolling reserve, %',
      rolling_reserve_period: 'Rolling reserve, days',
      min_amount: `Min amount, ${currency}`,
      max_amount: `Max amount, ${currency}`,
    };

    return formLabels[setting_code];
  };

  const transformEntries = (entries: IMIDSettingForm) => {
    const settings = getTypedObjectKeys(entries).reduce<TEditMIDSettings>((acc, key) => {
      const setting = midSettings.data.find(
        item => item.setting_code === key && item.value !== entries[key],
      );

      if (setting) {
        acc[key] = {
          setting_id: setting.id,
          value: entries[key],
        };
      }
      return acc;
    }, {});

    return { settings };
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formData = new FormData(e.currentTarget);
    const formValues = Object.fromEntries(formData.entries()) as IMIDSettingForm;
    const transformedEntries = transformEntries(formValues);

    if (isEmptyObject(transformedEntries.settings)) return;

    try {
      const result = await editEntity({ id: mid.id, ...transformedEntries });

      if ('data' in result) {
        closeModal();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getInputAttributes = (setting: IMIDSetting) => {
    const { setting_code } = setting;

    switch (setting_code) {
      case 'rolling_reserve_period':
      case 'hold':
        return {
          min: 0,
          max: 365,
          step: 1,
        };
      case 'rolling_reserve':
        return {
          min: 0,
          max: 100,
          step: 1,
        };
      case 'deposit_fee':
      case 'settlement_fee':
      case 'withdrawal_fee':
        return {
          min: 0,
          max: 100,
          step: 0.05,
        };
      case 'chargeback_fee':
      case 'refund_fee':
        return {
          min: 0,
          max: 100000,
          step: 1,
        };
      case 'min_amount':
        return {
          min: 0,
          max: parseFloat(maxAmount) || 1000000000,
          step: 1,
          onChange: (e: FormEvent<HTMLInputElement>) => setMinAmount(e.currentTarget.value),
        };
      case 'max_amount':
        return {
          min: parseFloat(minAmount) || 0,
          max: 1000000000,
          step: 1,
          onChange: (e: FormEvent<HTMLInputElement>) => setMaxAmount(e.currentTarget.value),
        };
      default:
        return {
          min: 0,
          step: 0.01,
        };
    }
  };

  const renderItem = (setting: IMIDSetting) => {
    const { setting_code, value } = setting;
    const inputAttributes = getInputAttributes(setting);

    return (
      <input
        type={!editable ? 'text' : 'number'}
        name={setting_code}
        defaultValue={value}
        readOnly={!editable}
        {...inputAttributes}
      />
    );
  };

  if (isLoading) return <LoadingSpinner />;

  if (midSettings) {
    const midName = `${mid.merchant.short_name}*${mid.currency_method.method.name}*${mid.traffic}*${mid.currency_method.currency.code}`;

    return (
      <>
        {isEditLoading && <Loading />}

        <h2>MID pricing</h2>
        <p>{midName}</p>
        <form className='popup-form' onSubmit={handleSubmit}>
          {midSettings.data.map(setting => {
            const { id, setting_code } = setting;
            const label =
              getFormLabels(setting_code, mid) || transformObjectKeyToTitle(setting_code);

            return (
              <FormItemWithError
                key={id}
                name={`settings.${setting_code}.value`}
                secondName='settings'
                label={label}
                isError={isError}
                error={error}
              >
                {renderItem(setting)}
              </FormItemWithError>
            );
          })}

          {isSuccess && <div className='form-success'>Successfully</div>}

          <div className='form-submit'>
            <button type='button' className='secondary-btn' onClick={closeModal}>
              Cancel
            </button>
            {!editable && (
              <button type='button' onClick={() => setEditable(true)}>
                Edit
              </button>
            )}
            {editable && (
              <button type='submit' disabled={isEditLoading}>
                Save
              </button>
            )}
          </div>
        </form>
      </>
    );
  }

  return null;
};
