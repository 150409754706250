import { ChangeEvent, FormEvent, useState } from 'react';

import { TOrder, useApproveOrderMutation } from '@entities/orders';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { FormItemWithError } from '@shared/components/Form';
import { useModal } from '@shared/components/Modal';
import { trimDecimalPart } from '@shared/utils/formatAmount';

import { IOrderModalContextProps } from '../model/types';

interface IProps<T> {
  order: T;
  refetch: () => void;
}

export const ApproveOrder = <T extends TOrder>({ order, refetch }: IProps<T>) => {
  const { requested_amount, actual_amount, id, customer_transaction_id, status_reason } = order;

  const [actualAmount, setActualAmount] = useState(actual_amount || requested_amount);
  const [customerTransactionId, setCustomerTransactionId] = useState(customer_transaction_id || '');
  const [reason, setReason] = useState(status_reason);

  const [approveOrder, { isLoading, isError, error, isSuccess }] = useApproveOrderMutation();
  const [localError, setLocalError] = useState<FetchBaseQueryError | null>(null);

  const validateUTR = (value: string): boolean => {
    const utrRegex = /^(\d{12})(\**)$/;
    return utrRegex.test(value);
  };

  const createUTRError = (): FetchBaseQueryError => {
    return {
      status: 422,
      data: {
        errors: {
          customer_transaction_id: [
            'UTR must be exactly 12 digits, optionally followed by asterisks (*)',
          ],
        },
      },
    };
  };

  const {
    closeModal,
    additionalContext: { setSelectedIds },
  } = useModal<IOrderModalContextProps>();

  const handleChangeCustomerTransactionId = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setCustomerTransactionId(value);

    if (value && !validateUTR(value)) {
      setLocalError(createUTRError());
    } else {
      setLocalError(null);
    }
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (customerTransactionId && !validateUTR(customerTransactionId)) {
      setLocalError(createUTRError());
      return;
    }

    try {
      const result = await approveOrder({
        id,
        actual_amount: actualAmount,
        customer_transaction_id: customerTransactionId,
        reason,
      });

      if ('data' in result) {
        setSelectedIds([]);
        closeModal();
        refetch();
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <h2>Approve Order</h2>
      <form className='popup-form' onSubmit={handleSubmit}>
        <FormItemWithError
          name='actual_amount'
          label='Actual Amount'
          isError={isError}
          error={error}
        >
          <input
            type='text'
            name='actual_amount'
            id='actual_amount'
            defaultValue={`${trimDecimalPart(actualAmount)}`}
            onChange={e => setActualAmount(e.target.value)}
          />
        </FormItemWithError>

        <FormItemWithError
          name='customer_transaction_id'
          label='UTR'
          isError={Boolean(localError) || isError}
          error={localError || error}
        >
          <input
            type='text'
            name='customer_transaction_id'
            id='customer_transaction_id'
            defaultValue={customerTransactionId}
            onChange={handleChangeCustomerTransactionId}
          />
        </FormItemWithError>

        <FormItemWithError name='reason' label='Reason' isError={isError} error={error}>
          <input
            type='text'
            name='reason'
            id='reason'
            defaultValue={reason}
            onChange={e => setReason(e.target.value)}
          />
        </FormItemWithError>

        {isSuccess && <div className='form-success'>Successfully approved</div>}

        <div className='form-submit'>
          <button type='submit' disabled={isLoading}>
            Approve
          </button>
          <button type='button' className='secondary-btn' onClick={closeModal}>
            Cancel
          </button>
        </div>
      </form>
    </>
  );
};
