import { useFilter } from '@features/table-filter';
import { useMerchantTestMode } from '@features/test-mode-toggler';
import { useRefetchCurrentPageData } from '@shared/hooks/useRefetchCurrentPageData';

import { useRequestDepositQuery } from '../api';
import type { IRequestDepositFilter } from '../model/types';

interface IProps {
  refreshInterval: string;
}

export const useRequestDeposits = ({ refreshInterval }: IProps) => {
  const filter = useFilter<IRequestDepositFilter>();
  useMerchantTestMode({ currentFilter: filter.currentFilter, updateFilter: filter.updateFilter });
  const {
    data: requests,
    isLoading,
    isError,
    error,
    isFetching,
    refetch,
  } = useRequestDepositQuery(filter.currentFilter.toString(), {
    pollingInterval: refreshInterval === 'off' ? undefined : parseInt(refreshInterval),
  });

  // Refetch data when timezone changes
  useRefetchCurrentPageData({ refetch });

  return {
    requests,

    isError,
    error,
    isLoading,
    isFetching,
    refetch,

    filter,
  };
};
