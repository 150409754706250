import { useEffect } from 'react';

import { TWalletType, useWalletType } from '@entities/wallets';
import { useFilter } from '@features/table-filter';
import { useMerchantTestMode } from '@features/test-mode-toggler';

import { useGetWalletsQuery, useGetWalletsDebugQuery } from '../api';
import type { IWalletFilter } from '../model/types';

interface IProps {
  type?: TWalletType;
  refreshInterval: string;
}

export const useWallets = ({ type, refreshInterval }: IProps) => {
  const filter = useFilter<IWalletFilter>();
  const { currentFilter, updateFilter, resetFilter } = filter;
  const isDebugMode = currentFilter.get('debug_mode') === '1';

  const currentWalletType = useWalletType();
  const hasSameType = currentWalletType === type;

  // Always set this parameters
  if (!currentFilter.get('without_settings')) currentFilter.set('without_settings', '1');
  if (!currentFilter.get('with_relations')) currentFilter.set('with_relations', '1');

  // Make sure the wallet type is set before any request is made
  if (!currentFilter.get('filter[type]')) {
    updateFilter({ 'filter[onlyParents]': 'true', 'filter[type]': type });
  }

  const midFilter = currentFilter.get('filter[mid]');
  if (midFilter) {
    currentFilter.delete('filter[onlyParents]');
  } else {
    currentFilter.set('filter[onlyParents]', 'true');
  }

  const queryOptions = {
    pollingInterval: refreshInterval === 'off' ? undefined : parseInt(refreshInterval),
    skip: false,
  };

  const regularQuery = useGetWalletsQuery(currentFilter.toString(), {
    ...queryOptions,
    skip: isDebugMode,
  });

  const debugQuery = useGetWalletsDebugQuery(currentFilter.toString(), {
    ...queryOptions,
    skip: !isDebugMode,
  });

  useMerchantTestMode({ currentFilter, updateFilter });

  const {
    data: wallets,
    isLoading,
    isError,
    error,
    isFetching,
    refetch,
  } = isDebugMode ? debugQuery : regularQuery;

  useEffect(() => {
    if (!hasSameType) {
      resetFilter();
      updateFilter({ 'filter[onlyParents]': 'true', 'filter[type]': type });
    }
  }, [type]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    wallets,
    isError,
    error,
    isLoading,
    isFetching,
    refetch,

    filter,
  };
};
