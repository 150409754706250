import { useState } from 'react';

import { NavLink } from 'react-router-dom';

import { withRoleAccess } from '@app/routes';
import useActions from '@app/store/hooks/useActions';
import { useCurrentUser } from '@entities/user';
import { ModalResetPassword } from '@features/modal-reset-password';
import { TestModeToggler } from '@features/test-mode-toggler';
import { Box } from '@mui/material';
import { HotkeysModal } from '@shared/components/Hotkeys';
import { useModal } from '@shared/components/Modal';

const ProfileNav = () => {
  const [isOpenNav, setOpenNav] = useState(false);
  const { clearToken, clearTestMode, clearCurrentUser, setPageTitle } = useActions();
  const TestModeTogglerWithRoleAccess = withRoleAccess(TestModeToggler, ['admin']);

  const { openModal } = useModal();
  const handleOpenHotkeysModal = () => {
    openModal(<HotkeysModal />, 'md');
    setOpenNav(false);
  };

  const handleOpenResetPasswordModal = () => {
    openModal(<ModalResetPassword />, 'md');
    setOpenNav(false);
  };

  const user = useCurrentUser();

  const handleLogout = () => {
    clearCurrentUser();
    clearToken();
    clearTestMode();

    setPageTitle('');
    setOpenNav(false);
  };

  return (
    <div className={`account-block ${isOpenNav ? 'is-visible' : ''}`}>
      <div
        className='account-pic'
        onClick={() => setOpenNav(true)}
        onKeyDown={() => setOpenNav(true)}
        role='button'
        tabIndex={0}
      ></div>
      <div className='account-popup'>
        <div className='account-name'>{user?.currentUser?.name || 'Unknown'}</div>
        <div className='account-email'>{user?.currentUser?.email || 'Unknown'}</div>
        <div className='account-nav'>
          <ul>
            <li>
              <TestModeTogglerWithRoleAccess />
            </li>
            <li>
              <NavLink to='/profile-settings'>Settings</NavLink>
            </li>
            {user?.hasLoggedAs === 'provider' && (
              <li>
                <Box component={'a'} onClick={handleOpenResetPasswordModal}>
                  Reset Password
                </Box>
              </li>
            )}
            <li>
              <Box component={'a'} onClick={handleOpenHotkeysModal}>
                Hotkeys
              </Box>
            </li>
            <li className='account-logout-li'>
              <div
                className='account-logout'
                onClick={handleLogout}
                role='button'
                tabIndex={0}
                onKeyDown={handleLogout}
              >
                Logout
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div
        className='account-popup-overlay'
        onClick={() => setOpenNav(false)}
        onKeyDown={() => setOpenNav(false)}
        role='button'
        tabIndex={0}
      ></div>
    </div>
  );
};

export default ProfileNav;
