import { FormEvent, useState } from 'react';

import { useFee } from '@entities/orders/transaction';
import {
  BanksSelectAsync,
  DepositFirstStepSelect,
  WithdrawalFirstStepSelect,
} from '@features/banks';
import { CurrenciesSelectAsync, CurrenciesSelectMulti } from '@features/currencies';
import { FormsSelect } from '@features/forms';
import { MerchantsSelectAsync } from '@features/merchants';
import { MethodsSelect } from '@features/methods';
import { MIDsSelect } from '@features/mids';
import { ProvidersSelectAsync } from '@features/providers';
import { TrafficsSelect } from '@features/traffic';
import { FormItemWithError, InputSwitch } from '@shared/components/Form';
import { useModal } from '@shared/components/Modal';
import { TUseMutationPost } from '@shared/types/fetch-data';
import { StringOnlyKeys } from '@shared/types/utils';
import { excludeComponentProps } from '@shared/utils/excludeComponentProps';
import { transformObjectKeyToTitle } from '@shared/utils/transformObjectKeyToTitle';

interface IProps<T, TResponse> {
  entityKeys: (keyof T)[];
  excludeFilterKeys?: string[];
  title: string;
  submitBtnText?: string;
  secondErrorNames?: Partial<Record<keyof T, string>>[];
  useCreateEntityMutation: TUseMutationPost<TResponse>;
  onTransformFormData?: (data: FormData) => FormData | Record<string, unknown>;
  onSuccess?: (data: TResponse) => void;
  renamedLabels?: Partial<Record<StringOnlyKeys<T>, string>>;
  refetch?: () => void;
  otherProps?: Record<string, any>; // eslint-disable-line @typescript-eslint/no-explicit-any
}

export const ModalNewEntity = <T, TResponse>({
  entityKeys,
  excludeFilterKeys = [],
  title,
  submitBtnText = 'Create',
  secondErrorNames = [],
  useCreateEntityMutation,
  onTransformFormData,
  onSuccess,
  renamedLabels,
  refetch,
  otherProps,
}: IProps<T, TResponse>) => {
  const [merchantId, setMerchantId] = useState('');
  const [currencyId, setCurrencyId] = useState('');
  const [midId, setMidId] = useState('');

  const { setAmount, setExchangeRate, fee, usdt_amount } = useFee({
    mid_id: midId,
  });

  const { closeModal } = useModal();

  const [createEntity, { isLoading, isError, error, isSuccess }] = useCreateEntityMutation();

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formData = new FormData(e.currentTarget);
    const transformedData = onTransformFormData ? onTransformFormData(formData) : formData;
    try {
      const result = await createEntity(transformedData as FormData);
      if ('data' in result) {
        closeModal();
        if (onSuccess) {
          onSuccess(result.data);
        } else {
          refetch && refetch();
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const hasSecondErrorName = (name: keyof T) => {
    return secondErrorNames.some(item => item[name]);
  };

  const getSecondErrorName = (name: keyof T) => {
    return secondErrorNames.find(item => item[name])[name];
  };

  const renderItem = (key: keyof T) => {
    const keyString = String(key);

    switch (key) {
      case 'description':
        return <textarea name={keyString} />;

      case 'email':
        return <input type='email' name={keyString} />;

      case 'logo':
        return <input type='file' name={keyString} />;

      case 'file':
        return <input type='file' name={keyString} accept='.csv' />;

      case 'login_credentials_json':
        return <textarea name={keyString} />;

      case 'merchant_id':
        return <MerchantsSelectAsync name={keyString} onChangeMerchant={setMerchantId} />;

      case 'mid_id':
        return <MIDsSelect merchantIds={merchantId ? [merchantId] : []} onChangeMID={setMidId} />;

      case 'provider_id':
        return <ProvidersSelectAsync />;

      case 'bank_id':
        return <BanksSelectAsync />;

      case 'type':
        return <input type='text' name={keyString} defaultValue={otherProps?.type} readOnly />;

      case 'traffic':
        return <TrafficsSelect />;

      case 'currency_id': {
        const neededProps = { merchantId };
        const props = excludeComponentProps(neededProps, excludeFilterKeys);

        return (
          <CurrenciesSelectAsync {...props} name={keyString} onChangeCurrency={setCurrencyId} />
        );
      }

      case 'currencies': {
        const neededProps = { merchantId };
        const props = excludeComponentProps(neededProps, excludeFilterKeys);
        return (
          <CurrenciesSelectMulti {...props} name={keyString} onChangeCurrency={setCurrencyId} />
        );
      }

      case 'method_id': {
        const neededProps = { merchantId, currencyId };
        const props = excludeComponentProps(neededProps, excludeFilterKeys);

        return <MethodsSelect {...props} />;
      }

      // create mids
      case 'form_id':
        return <FormsSelect name={keyString} />;

      // create settlement
      case 'fee':
        return <input type='text' name={keyString} value={fee} readOnly />;
      case 'usdt_amount':
        return <input type='text' name={keyString} value={usdt_amount} readOnly />;
      case 'amount':
        return <input type='text' name={keyString} onBlur={e => setAmount(e.target.value)} />;
      case 'exchange_rate':
        return <input type='text' name={keyString} onBlur={e => setExchangeRate(e.target.value)} />;

      case 'withdraw_first_step':
        return <WithdrawalFirstStepSelect name={keyString} />;

      case 'deposit_first_step':
        return <DepositFirstStepSelect name={keyString} />;

      case 'is_test':
        return <InputSwitch name={keyString} />;

      default:
        return <input type='text' name={keyString} />;
    }
  };

  return (
    <>
      <h2>{title}</h2>
      <form className='popup-form' onSubmit={handleSubmit}>
        {entityKeys.map(key => {
          const keyString = String(key);
          const label =
            renamedLabels?.[key as StringOnlyKeys<T>] || `${transformObjectKeyToTitle(keyString)}:`;
          const secondErrorNameProps = hasSecondErrorName(key)
            ? { secondName: getSecondErrorName(key) }
            : {};

          return (
            <FormItemWithError
              key={keyString}
              name={keyString}
              {...secondErrorNameProps}
              label={label}
              isError={isError}
              error={error}
            >
              {renderItem(key)}
            </FormItemWithError>
          );
        })}
        {isSuccess && <div className='form-success'>Successfully</div>}

        <div className='form-submit'>
          <button type='button' className='secondary-btn' onClick={closeModal}>
            Cancel
          </button>
          <button type='submit' disabled={isLoading}>
            {submitBtnText}
          </button>
        </div>
      </form>
    </>
  );
};
