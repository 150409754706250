import { useEffect } from 'react';

import { useFilter } from '@features/table-filter';
import { useMerchantTestMode } from '@features/test-mode-toggler';

import { useGetMerchantsQuery } from '../api';
import type { IMerchantFilter } from '../model/types';

interface IProps {
  name?: string;
  email?: string;
  domain?: string;

  disableUrlFilter?: boolean;
}

export const useMerchants = ({ name, email, domain, disableUrlFilter }: IProps = {}) => {
  const filter = useFilter<IMerchantFilter>({ disableUrlFilter });
  const { currentFilter, updateFilter } = filter;

  const {
    data: merchants,
    isLoading,
    isError,
    error,
    isFetching,
    refetch,
  } = useGetMerchantsQuery(currentFilter.toString());

  useMerchantTestMode({ currentFilter, updateFilter, filterKey: 'is_test' });

  useEffect(() => {
    updateFilter({ 'filter[name]': name });
  }, [name]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    updateFilter({ 'filter[email]': email });
  }, [email]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    updateFilter({ 'filter[domain]': domain });
  }, [domain]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    merchants,
    isError,
    error,
    isLoading,
    isFetching,
    refetch,

    filter,
  };
};
