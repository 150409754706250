import { useState } from 'react';

import { toast } from 'react-toastify';

import { useResetOperatorPasswordMutation } from '@entities/user';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { FormItemWithError } from '@shared/components/Form';
import { useModal } from '@shared/components/Modal';

import './modal-reset-password.css';

type PasswordField = 'old_password' | 'new_password' | 'new_password_confirmation';

export const ModalResetPassword = () => {
  const { closeModal } = useModal();
  const [resetPassword, { isError, isLoading, error }] = useResetOperatorPasswordMutation();
  const [showPasswords, setShowPasswords] = useState({
    old_password: false,
    new_password: false,
    new_password_confirmation: false,
  });

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      const formData = new FormData(e.target as HTMLFormElement);
      const result = await resetPassword({
        new_password: formData.get('new_password') as string,
        new_password_confirmation: formData.get('new_password_confirmation') as string,
        old_password: formData.get('old_password') as string,
      }).unwrap();
      if (result) {
        toast.success('Password reset successfully', {
          position: 'bottom-right',
        });
        closeModal();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const togglePasswordVisibility = (field: PasswordField) => {
    setShowPasswords(prev => ({
      ...prev,
      [field]: !prev[field],
    }));
  };

  const PasswordToggleButton = ({ field, isError }: { field: PasswordField; isError: boolean }) => {
    const className = isError ? 'error' : 'primary';
    return (
      <button
        type='button'
        onClick={() => togglePasswordVisibility(field)}
        className={`password-toggle-button shift-right`}
      >
        {showPasswords[field] ? (
          <VisibilityOff color={className} />
        ) : (
          <Visibility color={className} />
        )}
      </button>
    );
  };

  return (
    <>
      <h2>Enter new password</h2>
      <form className='reset-password-form' onSubmit={handleSubmit}>
        <FormItemWithError
          error={error}
          key={'old_password'}
          name={'old_password'}
          label={'Old Password'}
          isError={isError}
        >
          <div className='password-input-wrapper'>
            <input type={showPasswords.old_password ? 'text' : 'password'} name='old_password' />
            <PasswordToggleButton field='old_password' isError={isError} />
          </div>
        </FormItemWithError>

        <FormItemWithError
          error={error}
          key={'new_password'}
          name={'new_password'}
          label={'New Password'}
          isError={isError}
        >
          <div className='password-input-wrapper'>
            <input type={showPasswords.new_password ? 'text' : 'password'} name='new_password' />
            <PasswordToggleButton field='new_password' isError={isError} />
          </div>
        </FormItemWithError>

        <FormItemWithError
          error={error}
          key={'new_password_confirmation'}
          name={'new_password_confirmation'}
          label={'Confirm New Password'}
          isError={isError}
        >
          <div className='password-input-wrapper'>
            <input
              type={showPasswords.new_password_confirmation ? 'text' : 'password'}
              name='new_password_confirmation'
            />
            <PasswordToggleButton field='new_password_confirmation' isError={isError} />
          </div>
        </FormItemWithError>

        <div className='form-submit'>
          <button type='button' className='secondary-btn'>
            Cancel
          </button>
          <button disabled={isLoading} type='submit'>
            Reset Password
          </button>
        </div>
      </form>
    </>
  );
};
