import WebhookIcon from '@mui/icons-material/Webhook';
import { Tooltip } from '@mui/material';

type IProps = {
  status: string | null;
};

const MESSAGES: Record<number, string> = {
  NaN: 'No webhooks',
  1: '(Informational) → The request is received but not yet processed (rarely used in webhooks)',
  2: '(Success) → Webhook processed successfully',
  3: '(Redirect) → The webhook URL has moved; some services might not follow redirects',
  4: '(Client Error) → The receiving server rejected the webhook request (e.g., authentication failure, invalid data, rate limit exceeded)',
  5: "(Server Error) → The receiving server encountered an issue and couldn't process the webhook request",
};

export const WebHookStatus = ({ status }: IProps) => {
  const statusCode = parseInt(status);
  const statusGroup = Math.floor(statusCode / 100);

  let color: string;

  switch (statusGroup) {
    case 1:
      color = '#e8e00c';
      break;
    case 2:
      color = '#17da41';
      break;
    case 3:
      color = '#e8e00c';
      break;
    case 4:
    case 5:
      color = '#f44336';
      break;
    default:
      color = '#b0b0b0';
  }

  return (
    <Tooltip title={`${Number.isNaN(statusCode) ? '' : statusCode} ${MESSAGES[statusGroup]}`}>
      <WebhookIcon sx={{ color: color }} />
    </Tooltip>
  );
};
