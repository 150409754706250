import { FC, useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import { useGetMerchantsQuery } from '@entities/merchant';
import { IMID, useGetMIDsQuery } from '@entities/mid';
import { useCreateWalletMIDMutation } from '@entities/wallets';
import { getMIDName } from '@features/mids';
import { selectTestMode } from '@features/test-mode-toggler';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button, ButtonGroup } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import ErrorMessage from '@shared/components/ErrorMessage';
import { FormItemWithError, Select } from '@shared/components/Form';
import Loading from '@shared/components/Loading';
import { useModal } from '@shared/components/Modal';

import { NewWalletSettings } from './NewWalletSettings';

type TPairsType = 'upi' | 'bank_transfer';

interface IProps {
  parent_id: string;
  refetch: () => void;
  size?: number;
}

interface MerchantMidPair {
  id: string;
  merchantId: string;
  midIds: string[];
}

export const NewWalletMID: FC<IProps> = ({ parent_id, refetch, size = 500 }) => {
  const isTestMode = useSelector(selectTestMode);

  const params = new URLSearchParams();
  params.set('size', size.toString());
  params.set('filter[merchant_is_test]', isTestMode ? '1' : '0');

  const merchantParams = new URLSearchParams();
  merchantParams.set('size', size.toString());
  merchantParams.set('filter[is_test]', isTestMode ? '1' : '0');

  const {
    data: { data: merchants = [] } = { data: [] },
    isLoading: isLoadingMerchants,
    isError: isErrorMerchants,
    error: errorMerchants,
  } = useGetMerchantsQuery(merchantParams.toString());

  const {
    data: { data: mids = [] } = { data: [] },
    isLoading: isLoadingMIDs,
    isError: isErrorMIDs,
    error: errorMIDs,
  } = useGetMIDsQuery(params.toString());

  const [midsByMerchant, setMidsByMerchant] = useState<Map<string, IMID[]>>(new Map());
  const [merchantMidPairs, setMerchantMidPairs] = useState<MerchantMidPair[]>([
    {
      id: uuidv4(),
      merchantId: '',
      midIds: [],
    },
  ]);

  useEffect(() => {
    if (merchants && mids) {
      setMidsByMerchant(
        mids.reduce((acc, mid) => {
          acc.set(mid.merchant_id, [...(acc.get(mid.merchant_id) || []), mid]);
          return acc;
        }, new Map<string, IMID[]>()),
      );
    }
  }, [merchants.length, mids.length]); // eslint-disable-line react-hooks/exhaustive-deps

  const { closeModal, openModal } = useModal();
  const [createWalletMID, { isLoading, isError, error, isSuccess }] = useCreateWalletMIDMutation();

  const isUiBusy = isLoading || isLoadingMerchants || isLoadingMIDs;

  const handleAddPair = () => {
    setMerchantMidPairs([...merchantMidPairs, { id: uuidv4(), merchantId: '', midIds: [] }]);
  };

  const handleAddAllPairs = async (type: TPairsType) => {
    setMerchantMidPairs([]);
    const allPairs: MerchantMidPair[] = [];
    for (const merchant of merchants) {
      const merchantMids: IMID[] = midsByMerchant.get(merchant.id) || [];
      const filteredMids = merchantMids.filter(mid => mid.currency_method.method.name === type);
      for (const mid of filteredMids) {
        allPairs.push({ id: uuidv4(), merchantId: merchant.id, midIds: [mid.id] });
      }
    }
    setMerchantMidPairs(allPairs);
  };

  const handleRemoveAllPairs = () => {
    setMerchantMidPairs([]);
  };

  const handleRemovePair = (id: string) => {
    setMerchantMidPairs(prevPairs => prevPairs.filter(pair => pair.id !== id));
  };

  const handleMerchantChange = (id: string, merchantId: string) => {
    const updatedPairs = merchantMidPairs.map(pair =>
      pair.id === id ? { ...pair, merchantId, midIds: [] } : pair,
    );

    setMerchantMidPairs(updatedPairs);
  };

  const handleMidChange = (id: string, midId: string) => {
    const updatedPairs = merchantMidPairs.map(pair =>
      pair.id === id ? { ...pair, midIds: [midId] } : pair,
    );
    setMerchantMidPairs(updatedPairs);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const allMidIds = merchantMidPairs.flatMap(pair => pair.midIds);

    try {
      const result = await createWalletMID({ parent_id, mid_ids: allMidIds });
      if ('data' in result) {
        closeModal();
        openModal(
          <NewWalletSettings parent_name='Wallet' parent_id={parent_id} refetch={refetch} />,
        );
        refetch();
      }
    } catch (error) {
      console.error(error);
    }
  };

  if (isErrorMerchants || isErrorMIDs) {
    return <ErrorMessage error={errorMerchants || errorMIDs} />;
  }

  return (
    <>
      {(isLoading || isLoadingMIDs || isLoadingMerchants) && <Loading />}

      <h2>New Wallet MIDs</h2>
      <form className='create-wallet-mid-form' onSubmit={handleSubmit}>
        {merchantMidPairs.map((pair, idx) => {
          const midOptions = midsByMerchant.get(pair.merchantId) || [];

          return (
            <div className='form-row' key={pair.id}>
              <FormItemWithError
                name='merchant_id'
                label='Merchant:'
                error={error}
                isError={isError}
              >
                <Select
                  name='merchant_id'
                  options={merchants}
                  getOptionValue={merchant => merchant.id}
                  getOptionLabel={merchant => merchant.short_name}
                  selectedValue={pair.merchantId}
                  isDisabled={isLoading}
                  onChange={merchantId => handleMerchantChange(pair.id, merchantId)}
                >
                  <option value=''>Select merchant</option>
                </Select>
              </FormItemWithError>

              <FormItemWithError name='mid_ids' label='MID:' error={error} isError={isError}>
                <Select
                  name='mid_id'
                  options={midOptions}
                  onChange={midId => handleMidChange(pair.id, midId)}
                  getOptionValue={mid => mid.id}
                  getOptionLabel={getMIDName}
                  selectedValue={pair.midIds[0]}
                  isDisabled={isLoading || !pair.merchantId || midOptions.length === 0}
                >
                  <option value=''>Select MID</option>
                </Select>
              </FormItemWithError>

              {merchantMidPairs.length > 1 && (
                <IconButton onClick={() => handleRemovePair(pair.id)} color='error'>
                  <DeleteIcon />
                </IconButton>
              )}
            </div>
          );
        })}

        <div className='form-item add-wallet-mid-pair'>
          <ButtonGroup variant='outlined' aria-label='Basic button group'>
            <Button
              disabled={isUiBusy}
              variant='outlined'
              size='small'
              onClick={handleAddPair}
              color='primary'
            >
              Add pair
            </Button>
            <Button
              disabled={isUiBusy || merchants.length === merchantMidPairs.length}
              variant='outlined'
              size='small'
              onClick={() => handleAddAllPairs('upi')}
              color='primary'
            >
              Add all UPI
            </Button>
            <Button
              disabled={isUiBusy || merchants.length === merchantMidPairs.length}
              variant='outlined'
              size='small'
              onClick={() => handleAddAllPairs('bank_transfer')}
              color='primary'
            >
              Add all bank_transfer
            </Button>
          </ButtonGroup>

          <Button
            disabled={isUiBusy || merchantMidPairs.length === 0}
            variant='outlined'
            size='small'
            onClick={handleRemoveAllPairs}
            color='error'
          >
            Clear
          </Button>
        </div>

        {isSuccess && <div className='form-success'>Successfully</div>}

        <div className='form-submit'>
          <button type='button' className='secondary-btn' onClick={closeModal}>
            Cancel
          </button>
          <button type='submit' disabled={isUiBusy}>
            Create
          </button>
        </div>
      </form>
    </>
  );
};
