import { baseApi } from '@app/store/index.api';

import {
  IWallet,
  TWalletCredentials,
  IWalletUpdateQuery,
  IWalletUpdateStatusQuery,
  IWalletUpdateStatusResponse,
  IWalletUpdateModeQuery,
  IWalletUpdateModeResponse,
  IWalletMID,
  IWalletMIDQuery,
  IWalletResponse,
  INewWalletResponse,
  INewWalletSettingsResponse,
  IEditWalletSettingsQuery,
  IWalletSettingsResponse,
} from '../model/types';

const walletsApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getWallets: builder.query<IWalletResponse, string>({
      query: query => {
        return {
          url: `/wallets?${query}`,
        };
      },
    }),
    getWalletSettings: builder.query<IWalletSettingsResponse, string>({
      query: id => {
        return {
          url: `/wallets/${id}/settings`,
        };
      },
    }),
    getWalletsDebug: builder.query<IWalletResponse, string>({
      query: query => {
        return {
          url: `/wallets/debug?${query}`,
        };
      },
    }),
    createWallet: builder.mutation<INewWalletResponse, FormData>({
      query: formData => ({
        url: '/wallets',
        method: 'POST',
        body: formData,
      }),
    }),
    createWalletSettings: builder.mutation<INewWalletSettingsResponse, IEditWalletSettingsQuery>({
      query: query => ({
        url: `/wallets/${query.id}/setup-setting`,
        method: 'POST',
        body: query,
      }),
    }),
    updateWallet: builder.mutation<IWallet, IWalletUpdateQuery>({
      query: query => ({
        url: `/wallets/${query.id}`,
        method: 'PATCH',
        body: query,
      }),
    }),

    createWalletMID: builder.mutation<IWalletMID, IWalletMIDQuery>({
      query: query => ({
        url: '/wallets/child',
        method: 'POST',
        body: query,
      }),
    }),

    updateWalletStatus: builder.mutation<IWalletUpdateStatusResponse, IWalletUpdateStatusQuery>({
      query: query => ({
        url: `/wallets/${query.id}/status`,
        method: 'PATCH',
        body: {
          status: query.status,
        },
      }),
    }),

    updateWalletMode: builder.mutation<IWalletUpdateModeResponse, IWalletUpdateModeQuery>({
      query: query => ({
        url: `/wallets/${query.id}/mode`,
        method: 'PATCH',
        body: {
          mode: query.mode,
        },
      }),
    }),

    getWalletCredentials: builder.query<TWalletCredentials, string>({
      query: id => `/wallets/${id}/credentials`,
    }),
  }),
});

export const {
  useGetWalletsQuery,
  useGetWalletSettingsQuery,
  useGetWalletsDebugQuery,
  useLazyGetWalletsQuery,
  useCreateWalletMutation,
  useCreateWalletSettingsMutation,
  useUpdateWalletMutation,
  useUpdateWalletStatusMutation,
  useUpdateWalletModeMutation,
  useLazyGetWalletCredentialsQuery,
  useCreateWalletMIDMutation,
} = walletsApi;
