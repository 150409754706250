import { RootState } from '@app/store';
import { useTypedSelector } from '@app/store/hooks/useTypedSelector';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface WalletsExpandedState {
  expandedIds: string[];
}

const initialState: WalletsExpandedState = {
  expandedIds: [],
};

const walletsExpandedSlice = createSlice({
  name: 'walletsExpanded',
  initialState,
  reducers: {
    toggleExpand(state, action: PayloadAction<string>) {
      const id = action.payload;
      const index = state.expandedIds.indexOf(id);
      if (index === -1) {
        state.expandedIds.push(id);
      } else {
        state.expandedIds.splice(index, 1);
      }
    },
  },
});

export const actions = walletsExpandedSlice.actions;
export default walletsExpandedSlice.reducer;

export const useWalletsExpanded = () =>
  useTypedSelector((state: RootState) => state.walletsExpanded.expandedIds);
