import { RootState } from '@app/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ITestModeState {
  enabled: boolean;
}

const initialState: ITestModeState = {
  enabled: localStorage.getItem('testMode') === 'true',
};

const storeTestMode = (enabled: boolean) => localStorage.setItem('testMode', enabled.toString());

const testModeSlice = createSlice({
  name: 'testMode',
  initialState,
  reducers: {
    toggleTestMode: state => {
      state.enabled = !state.enabled;
      storeTestMode(state.enabled);
    },
    setTestMode: (state, action: PayloadAction<boolean>) => {
      state.enabled = action.payload;
      storeTestMode(state.enabled);
    },
    clearTestMode: state => {
      state.enabled = false;
      localStorage.removeItem('testMode');
    },
  },
});

export const actions = testModeSlice.actions;
export const { toggleTestMode, setTestMode, clearTestMode } = testModeSlice.actions;
export const selectTestMode = (state: RootState) => state.testMode.enabled;
export default testModeSlice.reducer;
