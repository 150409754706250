import { FC, useEffect } from 'react';

import Skeleton from 'react-loading-skeleton';

import { ICurrency, ICurrencyResponse, useCurrencies } from '@entities/currency';
import ErrorMessage from '@shared/components/ErrorMessage';
import { SelectMultiAsync } from '@shared/components/Form';

import 'react-loading-skeleton/dist/skeleton.css';

interface IProps {
  merchantId?: string;
  mid_ids?: string;
  name?: string;
  hasFirstSelectedOption?: boolean;
  onChangeCurrency?: (currencyId: string) => void;
  onSuccessfulFetch?: (isSuccess: boolean) => void;
}

export const CurrenciesSelectMulti: FC<IProps> = ({
  merchantId = '',
  mid_ids = '',
  name = 'currency_id',
  hasFirstSelectedOption = false,
  onSuccessfulFetch,
}) => {
  const { currencies, filter, isLoading, error, isFetching, isSuccess } = useCurrencies({
    merchantId,
    mid_ids,

    disableUrlFilter: true,
  });

  // successful fetch to parent component
  useEffect(() => {
    if (onSuccessfulFetch && isSuccess !== undefined) {
      onSuccessfulFetch(isSuccess);
    }
  }, [isSuccess, onSuccessfulFetch]);

  if (isLoading) return <Skeleton height={42} />;
  if (error) {
    return (
      <>
        <select></select>
        <ErrorMessage error={error} />
      </>
    );
  }

  if (currencies) {
    const hasCurrencies = currencies.data.length > 0;
    const firstSelectedCurrencyId = hasFirstSelectedOption
      ? { selectedValue: currencies.data[0].id }
      : {};

    return (
      <SelectMultiAsync<ICurrency, ICurrencyResponse>
        name={name}
        data={currencies}
        getOptionValue={currency => currency.id}
        getOptionLabel={currency => currency.code}
        filter={filter}
        filterKey='code'
        {...firstSelectedCurrencyId}
        isDisabled={!hasCurrencies}
        isLoading={isLoading || isFetching}
        error={error}
      />
    );
  }

  return null;
};
