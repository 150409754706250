import type { IWalletSettings } from '@entities/wallets';

export const WALLET_SETTINGS_KEYS: (keyof IWalletSettings)[] = [
  'max_amount',
  'min_amount',
  'wallet_fee',
  'wallet_transaction_fee',
];

export const WALLET_SETTINGS_DEFAULT_VALUES: Partial<IWalletSettings> = {
  max_amount: '500000',
  min_amount: '200',
  wallet_fee: '0',
  wallet_transaction_fee: '0',
};

export const assmebleWalletSettings = (fd: FormData) => {
  return WALLET_SETTINGS_KEYS.reduce((acc, key) => {
    acc[key] = fd.get(key) ? String(fd.get(key)) : WALLET_SETTINGS_DEFAULT_VALUES[key];
    return acc;
  }, {} as IWalletSettings);
};
