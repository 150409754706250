import {
  INewOrderTransactionQuery,
  IOrderTransaction,
  IOrderTransactionTableColumns,
  useCreateSettlementMutation,
  useEditSettlementMutation,
} from '@entities/orders/transaction';
import { useUserRole } from '@entities/user';
import { ModalEditEntity } from '@features/modal-entity-edit';
import { ModalNewEntity } from '@features/modal-entity-new';
import { ModalViewEntity } from '@features/modal-entity-view';
import { ITableActionsConfig, TableActionBtns } from '@features/table-action-btns';
import { formatAmount } from '@shared/utils/formatAmount';
import { removeKeysFromObject } from '@shared/utils/removeKeysFromObject';

interface IProps {
  refetch?: () => void;
}

export const useTableSettings = ({ refetch }: IProps) => {
  const userRole = useUserRole();

  const getColumnTitles = () => {
    // THead settings
    const columnTitles: IOrderTransactionTableColumns = {
      created_at: 'Date',
      amount: 'Amount',
      short_name: 'Merchant shortname',
      fee: 'Fee',
      exchange_rate: 'Exchange rate',
      usdt_amount: 'USDT amount',
      tronscan: 'Tronscan',
      actions: 'Actions',
    };

    const removeSettlementKeys: (keyof IOrderTransactionTableColumns)[] = [];
    if (userRole === 'merchant') {
      removeSettlementKeys.push('short_name');
    }
    return removeKeysFromObject(columnTitles, removeSettlementKeys);
  };

  // TBody settings
  const renderRow = (key: keyof IOrderTransactionTableColumns, settlement: IOrderTransaction) => {
    switch (key) {
      case 'amount':
        return `${formatAmount(settlement.amount)} ${settlement.currency}`;

      case 'fee':
        return (
          settlement.settlement_detail.fee_value && `${settlement.settlement_detail.fee_value}%`
        );

      case 'exchange_rate':
        return settlement.settlement_detail.exchange_rate;

      case 'usdt_amount':
        return settlement.settlement_detail.requested_usdt_amount;

      case 'tronscan':
        return settlement.settlement_detail.tronscan ? (
          <a
            href={`https://tronscan.org/#/transaction/${settlement.settlement_detail.tronscan}`}
            target='_blank'
            rel='noreferrer noopener'
          >
            Tronscan
          </a>
        ) : null;

      case 'actions': {
        const excludeKeys: (keyof IOrderTransaction)[] = [
          'id',
          'created_at',
          'amount',
          'currency',
          'method',
          'short_name',
          'mid_id',
          'settlement_detail',
        ];

        const additionalKeys = [{ tronscan: settlement.settlement_detail.tronscan }];

        const deepEntityValues = {
          settlement_detail: ['fee_value', 'exchange_rate', 'requested_usdt_amount', 'tronscan'],
        };

        const actionBtnsConfig: ITableActionsConfig = {
          edit: {
            isEnabled: true,
            isVisible: true,
            allowedRoles: ['admin'],
            popupElem: (
              <ModalEditEntity
                title='Edit Settlement'
                entity={settlement}
                excludeKeys={excludeKeys}
                additionalKeys={additionalKeys}
                useEditEntityMutation={useEditSettlementMutation}
                refetch={refetch}
              />
            ),
            popupSize: 'sm',
          },
          view: {
            isEnabled: true,
            isVisible: true,
            popupElem: (
              <ModalViewEntity
                entity={settlement}
                title='View Settlement'
                deepEntityValues={deepEntityValues}
              />
            ),
            popupSize: 'md',
          },
        };

        return <TableActionBtns config={actionBtnsConfig} />;
      }

      default:
        return settlement[key];
    }
  };

  // New settlement settings
  const newSettlementKeys: (keyof INewOrderTransactionQuery)[] = [
    'merchant_id',
    'currency_id',
    'mid_id',
    'amount',
    'exchange_rate',
    'fee',
    'usdt_amount',
  ];

  const newSettlementSecondErrorNames: Partial<Record<keyof INewOrderTransactionQuery, string>>[] =
    [{ currency_id: 'wallet' }, { fee: 'mid_id' }];

  const handleCreateSettlement = (formData: FormData) => {
    formData.delete('fee');
    formData.delete('usdt_amount');
    formData.set('status_url', 'https://www.google.com');

    return formData;
  };

  const renamedNewLabels = {
    currency_id: 'Currency',
    mid_id: 'MID',
    merchant_id: 'Merchant',
  };

  const NewSettlement = () => (
    <ModalNewEntity
      title='New Settlement'
      entityKeys={newSettlementKeys}
      secondErrorNames={newSettlementSecondErrorNames}
      useCreateEntityMutation={useCreateSettlementMutation}
      onTransformFormData={handleCreateSettlement}
      refetch={refetch}
      renamedLabels={renamedNewLabels}
    />
  );

  return {
    columnTitles: getColumnTitles(),
    renderRow,
    NewSettlement,
  };
};
