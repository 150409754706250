import { useState, useCallback, useEffect } from 'react';

import debounce from 'lodash/debounce';
import { useSelector } from 'react-redux';
import Select, { MultiValue } from 'react-select';

import { selectTestMode } from '@features/test-mode-toggler';
import { ISelectMultiOption, reactSelectMultiStyles } from '@shared/components/Form';
import { TTableColumnsFilter, useTableContext } from '@shared/components/Table';
import { IBaseObject, TUseLazyQuery } from '@shared/types/fetch-data';

import { MenuList, Option, ValueContainer } from './FilterSelectAsync';

const useSyncOptions = <T extends IBaseObject>(
  getFilterOptions: TUseLazyQuery<string, T>,
  labelKey: keyof T,
  valueKey: keyof T,
  relationFilterKey: string,
  filterTestModeKey?: string,
) => {
  const [inputValue, setInputValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState<ISelectMultiOption[]>([]);
  const [isInitialized, setIsInitialized] = useState(false);
  const isTestMode = useSelector(selectTestMode);

  const fetchOptions = async (input: string) => {
    setIsLoading(true);
    try {
      const params = new URLSearchParams({
        page: '1',
        size: '500',
        'filter[column]': relationFilterKey,
        'filter[search]': input,
      });

      if (filterTestModeKey) {
        params.set(`filter[${filterTestModeKey}]`, isTestMode ? '1' : '0');
      }

      const response = await getFilterOptions(params.toString());
      setOptions(
        response.data.data.map(item => ({
          value: String(item[valueKey]),
          label: String(item[labelKey]),
        })),
      );
      setIsInitialized(true);
    } finally {
      setIsLoading(false);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSyncOptions = useCallback(
    debounce(
      (input: string) => {
        fetchOptions(input);
      },
      300,
      { leading: true },
    ),
    [isTestMode],
  );

  const handleInputChange = (input: string) => {
    setInputValue(input);
    if (!isInitialized) {
      debouncedSyncOptions(input);
    }
  };

  return {
    inputValue,
    isLoading,
    handleInputChange,
    isInitialized,
    getSyncOptions: debouncedSyncOptions,
    options,
  };
};

interface IProps<T extends IBaseObject> {
  filterTestModeKey?: string;
  relationFilterKey: string;
  name: string;
  labelKey: keyof T;
  valueKey: keyof T;
  getFilterOptions: TUseLazyQuery<string, T>;
}

export const FilterSelectSync = <T extends IBaseObject>({
  filterTestModeKey,
  relationFilterKey,
  labelKey,
  valueKey,
  name,
  getFilterOptions,
}: IProps<T>) => {
  const { currentFilter, updateFilter, deleteFilterKey } = useTableContext<T>();
  const filterKey = `filter[${String(name)}][]`;
  const currentFilterValue = currentFilter.getAll(filterKey);

  const { inputValue, isInitialized, handleInputChange, options, getSyncOptions, isLoading } =
    useSyncOptions(getFilterOptions, labelKey, valueKey, relationFilterKey, filterTestModeKey);

  const [selectedOptions, setSelectedOptions] = useState<MultiValue<ISelectMultiOption>>(
    currentFilterValue.map((value: string) => ({
      value,
      label: value,
    })),
  );

  useEffect(() => {
    // Reset button was clicked and table filters were cleared outside of the component
    if (selectedOptions.length !== 0 && currentFilterValue.length === 0) {
      setSelectedOptions([]);
    }
  }, [currentFilterValue, selectedOptions]);

  const handleChange = (selectedOptions: MultiValue<ISelectMultiOption> | null) => {
    setSelectedOptions(selectedOptions as ISelectMultiOption[]);

    if (selectedOptions.length === 0) {
      deleteFilterKey(filterKey);
      return;
    }

    const newFilter = {} as Record<string, string>;
    selectedOptions.forEach((option, index) => {
      newFilter[`${filterKey}[${index}]`] = option.value;
    });
    updateFilter(newFilter as TTableColumnsFilter<T>);
  };

  const handleMenuOpen = () => {
    let shouldFetch = !isInitialized;
    if (Boolean(filterTestModeKey)) shouldFetch = true;
    if (shouldFetch) {
      getSyncOptions('');
    }
  };

  return (
    <Select
      className='table-filter-select-sync'
      components={{
        DropdownIndicator: null,
        IndicatorSeparator: null,
        MenuList,
        Option,
        ValueContainer,
      }}
      inputValue={inputValue}
      isLoading={isLoading}
      isMulti
      isClearable
      hideSelectedOptions={false}
      maxMenuHeight={200}
      menuPosition='fixed'
      menuShouldBlockScroll={true}
      menuShouldScrollIntoView={false}
      onChange={handleChange}
      onInputChange={handleInputChange}
      onMenuOpen={handleMenuOpen}
      options={options}
      styles={reactSelectMultiStyles}
      value={selectedOptions}
      closeMenuOnSelect={false}
    />
  );
};
