import { ComponentType, PropsWithChildren } from 'react';

import { useUserRole } from '@entities/user';
import type { TUserRoles } from '@entities/user';

export function withRoleAccess<P extends object>(
  WrappedComponent: ComponentType<P>,
  allowedRoles: TUserRoles[],
): ComponentType<P> {
  return function RoleBasedComponent(props: P) {
    const userRole = useUserRole();

    if (!allowedRoles.includes(userRole)) {
      return null;
    }

    return <WrappedComponent {...props} />;
  };
}

type IProps = PropsWithChildren<{
  roles: TUserRoles[];
}>;

export function ShowIfRole({ roles, children }: IProps) {
  const userRole = useUserRole();
  if (!roles.includes(userRole)) return null;
  return <>{children}</>;
}

export function HideIfRole({ roles, children }: IProps) {
  const userRole = useUserRole();
  if (roles.includes(userRole)) return null;
  return <>{children}</>;
}
